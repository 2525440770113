import React from "react"
import CTABanner from "../components/Organisms/CTABanner/CTABanner"
import WashType from "../components/Organisms/StaticSections/WashTypes"
import SEO from "../components/seo"
import {WithIntl} from "../components/Atoms/withTranslate"
import { localize } from "../components/Atoms/Language"

const WashTypesPage = ({language}) => (
  <>
    <SEO
      lang={language}
      title={localize("5")}
      description={localize("302")}
    />
    <WashType/>
    <CTABanner language={language}/>
  </>
)

export default WithIntl()(WashTypesPage)
