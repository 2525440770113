import React from "react"
import styled from "styled-components"
import { GlobalColors } from "../../../assets/styles/GlobalVariables"
import carWash6 from "../../../assets/images/carwash6.jpg"
import carWash7 from "../../../assets/images/carwash7.jpg"
import { localize } from "../../Atoms/Language"

const StyledWashesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;

  .variant1,
  .variant2 {
    flex: 1;
    width: 100%;
    max-width: 540px;

    img {
      max-width: 100%;
    }
  }

  .variant1 {
    padding-right: 70px;
  }

  @media screen and (max-width: 900px) {
    align-items: center;
    flex-direction: column;

    .variant1 {
      padding-right: 0;
      margin-bottom: 40px;
    }
  }

  h4 {
    margin: 8px 0;
  }

  p {
    color: ${GlobalColors.gray600};
  }
`

const StyledUnderHeader = styled.p`
  display: block;
  color: ${GlobalColors.gray600};
  margin: 0;
`

const StyledHeader = styled.h1`
  margin-bottom: 16px;
`

const WashType = () => (
  <section className="container mt8 mb16">
    <StyledHeader className="header2">{localize("5")}</StyledHeader>
    <StyledUnderHeader className="body-large">{localize("297")}</StyledUnderHeader>
    <StyledWashesContainer className="mt8">
      <div className="variant1">
        <img src={carWash6} alt={localize("349")} />
        <h4 className="header4">{localize("298")}</h4>
        <p className="body">{localize("299")}</p>
      </div>
      <div className="variant2">
        <img src={carWash7} alt={localize("350")} />
        <h4 className="header4">{localize("300")}</h4>
        <p className="body">{localize("301")}</p>
      </div>
    </StyledWashesContainer>
  </section>
)

export default WashType
